import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Skill Practice: Seated Leg Raise 4×10`}</p>
    <p>{`then,`}</p>
    <p>{`15:00 AMRAP of:`}</p>
    <p>{`10-Med Ball Burpee to Squat Clean (20/14)`}</p>
    <p>{`20-V Ups`}</p>
    <p>{`20-Back Extensions`}</p>
    <p>{`10 per arm-Russian KBS’s (53/35)`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The Ville will reopen on June 1st at 6:00am!  Check out the
Schedule page for an updated list of class times.  We hope to get back
to our normal class schedule asap and will keep you updated on any
changes.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      